
import React, {useState} from "react"
import { Link } from "gatsby-plugin-modal-routing-3"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Circle from "../components/circle"
import Sidepic from "../components/sidepic"
import Banner from "../components/banner"
import Threepics from "../components/threepics"
import Twopics from "../components/twopics"
import Tofu from "../images/TOFU.gif"
import styled from "@emotion/styled"
import colors from "../components/colors"
import bgTofu from "../images/bgTofu.svg"
import { useFlip, FlipProvider } from 'react-easy-flip'
import pray from "../images/pray.gif"
import ButtonMailto from "../components/mail"
import { nominalTypeHack } from "prop-types"
import FlipCard from "../components/flip"
import PopUp from "../components/popup/popupelement"


import {
  faShoppingBasket,
  faHandsHelping,
faReply} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTheme } from "@emotion/react"


library.add (faShoppingBasket,faHandsHelping, faReply)

const breakpoints =[820]
const BoxShadow = styled.div 
`box-shadow: rgba(240, 46, 170, 0.4) 0px 5px inset, rgba(240, 46, 170, 0.3) 0px 10px, rgba(240, 46, 170, 0.2) 0px 15px inset, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 170, 0.05) 0px 25px;
&:after{ box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  flex:1;

}
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Button = styled.button`
  color: white;
  text-decoration: none;
  border: medium dashed white;
  background: rebeccapurple ;
  cursor: pointer;
`;

const WhatHappenedNext = styled.div
`boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset"`

const mq = breakpoints.map(
  bp => `@media(min-width: ${bp}px)`
)

const cards = [
  
  {
    id: "Inhaltsstoffe",
    variant: "click",
    front:  
       <h2
    css={{
      flex:"1",
      textAlign:"center",
      margin:'0'
    }}>Inhaltsstoffe <FontAwesomeIcon icon="reply"/> </h2>
    ,
    back: <p
    css={{
    flex:"1",
    margin:'0',
    display:'inherit',
    [mq[0]]:{
      display:'inherit',
    }
  }}>
      Sojabohnen, Wasser <br></br> Tofu = + Calciumsulfat (Gips) 
    <br></br> Okarabällchen = Okara + Weizen v Reis v Maismehl v X
     <FontAwesomeIcon icon="reply"/></p>
  }
];
const threepics = [
  {
   id: "1",
   variant: "click",
   front:  
            <img title="drück mich" src={Tofu} style={{
              boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px ',
              border: "medium dashed white",
            }} />

     ,
    back:<p><h3>limeriki</h3>Tofu für Freunde - nachhaltig.
    </p>,
    
  },
  { id: "2",
    variant: "click",
    front:
      <BoxShadow><Image title="drück mich" src="threepic2.jpg" /></BoxShadow>
      ,
    back: 
    <p>Unser Kollektiv besteht aus Künstlern, Köchen, Philosophen, Programmierern, Träumern und
    Unternehmern. </p>,
  },
  { 
    id: "3",
   variant: "click",
   front:
    <BoxShadow><Image title="drück mich" src="threepic3.jpg" /></BoxShadow>
    ,
  back:<p>
  Kochexperimente, kultursoziologische Arbeit, kulinarische Feldforschung
  </p>,
  }
]


const IndexPage = () => (
  
  <FlipProvider>
  <Layout data-flip-root-id="FlipAll">
 <Link  css={{
    gridRow: "1",
    gridColumn: "6",
    justifySelf:"end",
    border: "medium dashed white",
    alignSelf:"center",
    color: "white",
    boxShadow:"rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px inset",
    padding:"1em",
    textDecoration: "none",
     [mq[0]]:{
      gridColumn:"5",
     },
  }}
            to="/wer-wir-sind/" asModal> <h2 title = "Wer wir sind">is(s)t?</h2></Link>
  <SEO title="Home" />
   
    <Banner css={{
        backgroundColor: colors.śchlagSch,
        backgroundImage: bgTofu,
        boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
        padding:"0.5em",
        paddingLeft:"5em",
        display:`flex`,
        flexFlow:'column',
        justifyContent:"center",
        alignConten:"center",
        [mq[0]]:{
          gridRow:`1`,
          gridColumn: '7/11',
          padding:"2em 0 1em 1em",
        },
    }}
    > <h2
    css={{
      flex:"1",
      textAlign:"center",
      padding: "0.5em",
      margin: "0",
    }}>Tofu für Freunde</h2>
    </Banner>
   
<div id="inhaltsstoffe" css={{
      gridRow:"8",
      gridColumn:"2/7",
      margin:"1em",
      display:"flex",
      flexFlow:"column",
      padding:"0.5em",
      boxShadow:" rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
      [mq[0]]:{
        gridRow:"2",
        gridColumn:"7/10",
        padding: "1em",
      },
    }}>
       {cards.map((card) => (
            <FlipCard key={card.id} card={card} />
          ))}
</div>
 

    <Sidepic
    css={{
          
        [mq[0]]:{
          gridRow:`1/6`,
          gridColumn: '6'
        },
    }}> 
      <BoxShadow>
        <Image src="sidepic1.jpg"></Image>
      </BoxShadow>
      <BoxShadow>
        <Image src="sidepic2.jpg"></Image>
      </BoxShadow>
      <BoxShadow>
        <Image src="sidepic3.jpg"></Image>
      </BoxShadow>
      <BoxShadow>
        <Image src="sidepic4.jpg"></Image>
      </BoxShadow>
      <BoxShadow>
        <Image src="sidepic5.jpg"></Image>
      </BoxShadow>
      </Sidepic>
    <Twopics
    css={{
          padding:"0.5em",
         boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
      [mq[0]]:{
        gridRow:`3`,
        gridColumn: '7/10',
        flexFlow:`row`,
              },
  }}>
     <div css={{
        boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
     }}>
      <h2 
      css= {{
    margin:'0',
  }} >Tofu </h2> <p> grad nich </p>
  </div>
  <div css={{
        boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
     }}>
      <h2 
      css= {{
    margin:'0',
  }} >Okara    </h2>
  <p>ab 0,60€ pro 100g</p>
  </div>
     <div css={{
        boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
     }}>
        <h2 css= {{
    margin:'0',
  }} >Okarabällchen  </h2>
  <p>ab 1,-€</p> 
  </div>
    </Twopics>
  /*
       <div   css={{
      flex:'1',
      gridColumn:'2/7',
      gridRow:'10/11',
      padding:'1em',
      [mq[0]]:{
        gridRow:'4/11',
        gridColumn:'7/12',
      }
    }}> 
     <div css={{
        boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
     }}>
        <h3 css= {{
    margin:'0',
  }} > Fr 16.9. Parking Day
  </h3>  
      <b></b>
      <p
      css={{
      margin:'0',
      display:'none',
      [mq[0]]:{
        display:"inherit",
      }
      }}>Okarabällchen + mehr</p>
    </div>
    <div css={{
        boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
     }}>
        <h3 css= {{
    margin:'0',
  }} >  
  </h3> 
      <b></b>
      <p
      css={{
      margin:'0',
      display:'none',
      [mq[0]]:{
        display:"inherit",
      }
      }}> </p>
    </div>

    <div css={{
        boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
     }}>
        <h3 css= {{
    margin:'0',
  }} >   Mo Mittagstisch N46 12-14
  </h3>  
      
      <p
      css={{
      margin:'0',
      display:'none',
      [mq[0]]:{
        display:"inherit",
      }
      }}></p>
    </div>
    
  </div>
  
 
    <Circle css={{
      margin:"-30px",
      flex:1,
      maxHeight: "38vh",
      [mq[0]]:{
        gridColumn:`1/6`,
        gridRow:`2/11`,
        margin:"0",
        maxHeight:"100%",
      }
    }}>
      <div css={{
        display: "grid",
        maxWidth: "100%",
        maxHeight: "70%",
        gridTemplateAreas: '" text1 threepic text2 " " . threepic . " " button1 threepic button2"',
        gridGap:"1rem",
        padding:"1rem",
        justifyItems:"center",
        justifyContent:"space-around",
        gridTemplateColumns:`5em 1fr 5em`,
        [mq[0]]:{
          gridTemplateColumns:`10em 1fr 10em`,
          padding:'4rem',
          maxHeight:'100%',
        }
      }}>
        <div css={{
          gridArea:"text1",
          display:"flex",
          flexFlow:"column",
          justifyContent:"space-between",
          maxWidth:"100%",
          boxShadow:"rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px inset",
          lineHeight: "30px",
          padding:"3em 1em",
          display:"none",
          [mq[0]]:{
            display:"inherit",
          }
        }}>   
          <p>Du kannst unsere Tofu und Okara Produkte bequem reservieren oder gegen Gebühr liefern lassen. Sonst kommst du auf gut Glück</p>
        </div>


        <Button css={{
          gridArea:"button1",
          borderRadius:"360px",
          padding:"-10em",
          [mq[0]]:{
            padding: "8px 0",
            lineHeight: "20px",
            margin:"0",
          }
        }}>      
        <ButtonMailto 
            title="bestellen" 
            mailto="mailto:bestellen@limeriki-tofu.de" 
            icon="shopping-basket"> 
        </ButtonMailto>
        </Button>
          
        <div css={{
          gridArea:"text2",
          display:"flex",
          flexFlow:"column",
          justifyContent:"space-between",
          maxWidth:"100%",
          boxShadow:"rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px inset",
          lineHeight: "30px",
          padding:"3em 1em",
          display:"none",
          [mq[0]]:{
            display:"inherit",
          }
        }}>   
          <p>Wir bieten Workshops zur Tofuproduktion an, oder du steigst gleich in die Produktion mit ein.</p>
          
        
        </div>
        <Button css={{
          borderRadius:"360px",
          gridArea:"button2",
          marginLeft:"0em",
          [mq[0]]:{
            padding: "8px 0",
            lineHeight: "20px",
            margin:"0",
          }
        }}><ButtonMailto label="mitmachen"
         mailto="mailto:mitmachen@limeriki-tofu.de" 
         icon="hands-helping" /></Button>
          </div>


    </Circle>
  
    <Threepics
      css={{
          marginTop:"-3em",
          
        [mq[0]]:{
          gridRow:`2 / 11`,
          gridColumn: '3',
          flexFlow:`column`,
          padding: `0em`,
          flex:1,
          marginBottom:"-3em",
        }
    }}>
      
{threepics.map((card) => (
            <FlipCard key={card.id} card={card} />
          ))}
    </Threepics> 
    
    

  </Layout>
  </FlipProvider>
      )

  

export default IndexPage